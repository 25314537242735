<template>
    <div class="inputglya">
        <v-textarea  :type="type" :label="label_show"  :placeholder="label" :solo="solo" :outlined="outlined" :filled="filled" :value="value"
        @input="updateValue"  :error-messages="nameErrors" :rows="rows" :auto-grow="auto_grow" :counter="counter" 
></v-textarea>
    </div>



</template>

<script>

// import { validationMixin } from 'vuelidate'
// import { required, minLength } from 'vuelidate/lib/validators'


export default {

    name:"BaseTextArea",

    props: {
        validador:{
            default:"namesSurnames"
        }, solo:{}, outlined:{default:false}, filled:{},type:{},label:{},transform:{},idtype:{default:"CC"},
        rows:{},
        counter:{default:true},
        auto_grow:{default:true}, value:String
        
    },
    model:{
        prop:"value",
        event:"input"
    },
    data() {
        return {
            // inputIn: this.$props.value,
            id: Math.floor(Math.random() * 1000000),
            minl: 5,
            inputIn: this.$attrs.value,
            showlabel: false,
            // validations: {
            //     namesSurnames: {
            //         minLength: 3
            //     }
            // },
            // jsback: this.$store.state.validations,

            // test: {
            //     required,
            //     minLength: minLength(3)
            // }, 
            regular:this.$store.state.idTypeListRegex[this.$props.idtype],
            // regularExpressionIdType: this.$store.dispatch("putValidationNid",this.regular[this.$props.idtype]),
            // fromvuex:this.$store.state.validations2[this.$props.validador],
            // fromback: this.$store.state.validations[this.$props.validador]

            // validacion:this.$store.state.validaciones[this.validador]
            // validacion: {
            //     minLength: eval("minLength(this.minl)")
            // },
            
        
        }
    },
    mounted() {
        this.eval_errors();
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value);
            //this.eval_errors()
            this.$emit('changeglya');
        },
        changeglya(){
            this.$emit('changeglya');
        },        
        // eval_errors: function () {
        //     if (this.nameErrors.length == 0) {
        //         this.$store.dispatch("puterror", { [this.id]: 0 })
        //     } else {
        //         this.$store.dispatch("puterror", { [this.id]: 1 })
        //     }
        // },
        set_input: function () {
            this.inputIn = this.$props.value
            return this.inputIn
        },
        set_label: function () {
            this.showlabel = true
            return this.showlabel
        }

    },
    computed: {  
        watch_inputIn: function () {
            return this.set_input()
        },  
        label_show: function () {
            let label = this.$props.label
            if (this.showlabel) {
                label = `${this.$props.label}${'*'}`
            }
            return label
        },   
        idtypeList: function () {
            return this.$store.state.idTypeList
            // v-validate="'required|email'"
        },
        // validator: function () {
        //      return this.$store.state.validations2[this.$props.validador]
        // },
        eval_required_show: function () {
            return this.$store.state.show_required
        },
        // nameErrors() {
        //     // this.$store.dispatch("putValidationNid", this.regular) 
        //     let errors = []
        //     if (!this.$v.inputIn.maxLength_xs && this.validator['maxLength_xs'] !== undefined ) {
        //         errors.push("Debe tener máximo de 50 caracteres")
        //     }
        //     if (!this.$v.inputIn.maxLength_s && this.validator['maxLength_s'] !== undefined ) {
        //         errors.push("Debe tener máximo de 200 caracteres")
        //     }
        //     if (!this.$v.inputIn.maxLength_m && this.validator['maxLength_m'] !== undefined ) {
        //         errors.push("Debe tener máximo de 400 caracteres")
        //     }
        //     if (!this.$v.inputIn.maxLength_l && this.validator['maxLength_l'] !== undefined ) {
        //         errors.push("Debe tener máximo de 600 caracteres")
        //     }
        //     if (!this.$v.inputIn.minLength && this.validator['minLength'] !== undefined ) {
        //         errors.push("Debe tener mínimo 3 caracteres")
        //     }
        //     if (!this.$v.inputIn.required && this.validator['required'] !== undefined) {
        //         if (this.eval_required_show) {
        //             errors.push('¡El campo es requerido!')
        //         } else {
        //             errors.push(' ')
        //         }
        //         this.set_label()
        //     }

        
            
        //     return errors
        // }       
        
    },
    components: {
    },

    // mixins: [validationMixin],

    // validations: function(){  
    //     if (this.minl>0) {

    //         return {
    //             inputIn: eval(this.validator)
    //         }  
        
    //     }
    //     return {
    //         x: {
    //             required,
    //             minLength: minLength()
    //         }
    //     }
    // }
}

</script>

<style>

</style>