<template>
    <div>
        <v-container class="pa-5">
            <v-row class="d-flex justify-center mb-5">
                <v-col cols="8" sm="6" class="text-center">
                    <h1 color="purple_glya_dark">¿Fue posible realizar el servicio?</h1>
                    <v-row class="d-flex justify-space-around mt-5">
                        <v-btn class="white--text" :color="this.$store.state.taskOk == true ? 'green' : 'grey'" @click="changeConfirmation(true)" rounded x-large>Si</v-btn>
                        <v-btn class="white--text" :color="this.$store.state.taskOk == false ? 'red' : 'grey'" @click="changeConfirmation(false)" rounded x-large>No</v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-5" v-if="this.$store.state.taskOk == true">
                <h1 class="purple_glya_dark--text mb-3">Datos Cuidador/Responsable</h1>
                <v-col cols="12" class="pt-0 pb-0">
                    <BaseInput label="Nombre completo" outlined myclass="element" v-model="$store.state.careGiverData.fullName"></BaseInput>
                </v-col>
                <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <BaseSelect label="Tipo ID" outlined  myclass="element" :opciones="this.$store.state.idTypes" v-model="$store.state.careGiverData.idType"></BaseSelect>
                </v-col>
                <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <BaseInput label="Número de identificación" outlined myclass="element" v-model="$store.state.careGiverData.idNumber"></BaseInput>
                </v-col>
                <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <BaseSelect label="Parentesco" outlined  myclass="element" :opciones="this.$store.state.relationships" v-model="$store.state.careGiverData.relationship"></BaseSelect>
                </v-col>
            </v-row>
            <v-row class="mt-5" v-if="this.$store.state.taskOk == false">
                <v-col cols="12" class="pt-0 pb-0">
                    <BaseTextArea type="text" label="Especifique el motivo" rows="6"  outlined  myclass="element"></BaseTextArea>
                </v-col>
            </v-row>

            <div class="navigation_form">
                <v-col cols="12" class="d-flex justify-space-between">
                  
                  <div></div>
                  <v-btn class="white--text" rounded color="purple_glya" @click="activityForm">
                    Siguiente <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>

                </v-col>
            </div>
        </v-container>
    </div>
</template>
<script>
    import BaseInput from "@/components/forms_items/BaseInput.vue";
    import BaseSelect from "@/components/forms_items/BaseSelect.vue";
    import BaseTextArea from "@/components/forms_items/BaseTextArea.vue";

    export default {
        components:{
            BaseInput,
            BaseSelect,
            BaseTextArea
            
        },
        
        created() {
            this.$store.commit('SET_LAYOUT', 'LayoutForm')
        },
        methods: {
            activityForm: function(){
                if (this.$store.state.taskOk==true) {
                    this.$router.push('/form');
                }
            },
            changeConfirmation(newValue)
            {
                this.$store.state.taskOk=newValue
            }
        },
         
    };

</script>