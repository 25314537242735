<template>
        <v-select :items="opciones" item-text="key" item-value="value" class="inputglya" :outlined="outlined" v-model="valuey" :label="label_show"
        @change="updateValue" :class="myclass"> </v-select>
</template>

<script>

    import axios from "axios"
    // import { validationMixin } from 'vuelidate'
    // import { required } from 'vuelidate/lib/validators'
    export default {

        props:{
            label:{
                type:String,
                default:''
            },
            myclass:{
                type:String,
                default:''
            },
            variant:{
                type:String,
                default:''
            },
            modelValue:{
                type:[String, Number],
                default:''
            },
            opciones:{
                type:{},
                default(){
                    return {}
                }
            },
            list:{
                type:String
            },
            model:{
                type:String
            },
            valuex:{
                type:String
            },
            validador: {
                default: 'none'
            },
            outlined:{default:false},
            
        },
        model: {
            prop: "value",
            event: "change"
        },
        data() {
            return{
                //datasearch: this.$props.opciones,
                valuey: this.$attrs.value,
                // fromvuex: this.$store.state.validations2[this.$props.validador],
                minl: 5,
                id: Math.floor(Math.random() * 1000000),
                showlabel: false
            }
        },
        mounted() {
            this.searchList();
            this.eval_errors();
        },
        methods: {
            updateValue() {
                //value=this.valuey
                
                this.$emit('change', this.valuey);
                //this.$emit('changeglya');
                if (this.label == "Tipo ID") {
                    //var regular = this.$store.state.idTypeListRegex[value]
                    //this.$store.dispatch("putValidationNid",regular)
                }
                this.eval_errors()
            },
            changeglya() {
                this.$emit('changeglya');
            }, 
            set_label: function () {
                this.showlabel = true
                return this.showlabel
            },
            searchList: function () {
                axios.get(`${this.$URL}/simpleSelect?const=${this.list}&model=${this.model}`).then(res => {
                    this.datasearch = res.data.results
                })
                // if (this.prueba) {
                // }

            },
            // eval_errors: function(){
            //     if (this.nameErrors.length == 0) {
            //         this.$store.dispatch("puterror", { [this.id]: 0 })
            //     } else {
            //         this.$store.dispatch("puterror", { [this.id]: 1 })
            //     }
            // }
        },
        computed: {
            eval_required_show: function () {
                return this.$store.state.show_required
            },
            label_show: function () {
                let label = this.$props.label
                if (this.showlabel) {
                    label = `${this.$props.label}${'*'}`
                }
                return label
            },
            nameErrors() {
                let errors = []
                if (!this.$v.valuey.required && this.fromvuex['required'] !== undefined) {
                    if (this.eval_required_show) {
                        errors.push('¡El campo es requerido!')
                    } else {
                        errors.push(' ')
                    }
                    this.set_label()
                }               
                return errors
            }
        },
        // mixins: [validationMixin],
        // validations: function () {
        //     if (this.minl > 0) {
        //         return {
        //             valuey: eval(this.fromvuex)
        //         }
        //     }
        //     return {
        //         x: {
        //             required
        //         }
        //     }
        // }



    }

</script>

<style>



</style>

