<template>
    <div>
        <v-toolbar class="toolbarglya elevation-0 indigo lighten-4 fixed-bar" >
            <div class="d-flex justify-space-between justify-end top-bar">
                <v-btn icon @click="isOpened = !isOpened" v-if="isOpened==false">
                    <v-avatar class="white border-avatar mt-1" size="40" >
                        <v-img src="../../assets/perfil.jpg" alt=""></v-img>
                    </v-avatar>
                </v-btn>
                <div></div>
                <v-btn icon>
                <v-icon color="white">mdi-bell</v-icon>
                </v-btn>
            </div>
        </v-toolbar>
    
        <div class="cortina" v-if="isOpened==true" @click="resetExpandir()"></div>

        <div class="sidebar" :class="isOpened ? 'open' : ''" :style="cssVars">
          <img src="../../assets/logo-gdi.svg"   alt="menu-logo" class="icon logo-gdi">
          <div class="logo-details d-flex flex-column" style="margin: 6px 14px 0 14px;" v-if="isOpened==true">
            <v-avatar class="white border-avatar icon" size="100">
              <v-img src="../../assets/perfil.jpg" alt=""></v-img>
            </v-avatar>
            <h3 class="white--text mt-5 icon">Diana Villa</h3>
          </div>
    
          <div style="display: flex ; flex-direction:column; justify-content: space-between; flex-grow: 1; max-height: calc(100% - 60px); ">
            <div class="my-scroll">
              <ul class="nav-list" style="overflow: visible;">
                <div @click="isOpened = true">
                  <span v-for="(menuItemFather, index) in menuItems" :key="index">
                    <li>

                      <div @click="menuItemFather.expand = !menuItemFather.expand">
                        <router-link :to="menuItemFather.link" v-if="menuItemFather.children.length == 0"><i class="bx"
                            :class="menuItemFather.icon || 'bx-square-rounded'" />
                          <span class="links_name">{{ menuItemFather.name }}</span>
                        </router-link>
                        <button v-else><i class="bx" :class="menuItemFather.icon || 'bx-square-rounded'" />
                          <span class="links_name">{{ menuItemFather.name }}</span>
                          <i class='bx chevron-position' :class="menuItemFather.expand == false ? 'bx-chevron-down'  : 'bx-chevron-up'" ></i>
                        </button>
                      </div>
    
                      <v-expand-transition>
                        <v-card v-show="menuItemFather.expand == true" color="sub-menu-color" class="mx-auto sub-menu"
                          rounded="false" elevation="1" v-if="menuItemFather.children.length > 0">
                          <span v-for="(menuItem, index) in menuItemFather.children" :key="index">
                            <ul>
                              <li>
                                <div>
                                  <router-link class="sub-menu-item" :to="menuItem.link"><i class="bx"
                                      :class="menuItem.icon || 'bx-square-rounded'" />
                                    <span class="links_name" @click="menuItemFather.expand = !menuItemFather.expand">{{
                                    menuItem.name
                                    }}</span>
                                  </router-link>
                                </div>
                              </li>
                            </ul>
                          </span>
                        </v-card>
                      </v-expand-transition>

                    </li>
                  </span>
                </div> 
              </ul>
            </div>
          </div>

        </div>
      </div>
    </template>
  
  
  <script> 
    var arrayIds = undefined
    import store from '@/store'
    
    export default {
      name: 'MenuSidebar',
      props: {
        //! Menu settings
        isMenuOpen: {
          type: Boolean,
          default: false,
        },
        menuTitle: {
          type: String,
          default: 'GDI',
        },
        menuLogo: {
          type: String,
          default: (''),
        },
        menuLogoGlya: {
          type: String,
          default: (''),
        },
        menuIcon: {
          type: String,
          default: 'bxl-c-plus-plus',
        },
        isPaddingLeft: {
          type: Boolean,
          default: true,
        },
        // menuOpenedPaddingLeftBody: {
        //   type: String,
        //   default: '250px'
        // },
        // menuClosedPaddingLeftBody: {
        //   type: String,
        //   default: '50px'
        // },
        showit:{
          type: Boolean,
          default:true
        },
  
        //! Menu items
        menuItems: {
          type: Array,
          default: () => [
            {
              link: '/main',
              name: 'Mis actividades',
              tooltip: 'Mis actividades',
              icon: 'bx-grid-alt',
              children:[]
            },
            // {
            //   link: '/orders',
            //   name: 'Mis órdenes',
            //   tooltip: 'Mis órdenes',
            //   icon: 'bx-folder',
            //   children: []
            // },
            // {            
            //   name: 'Mis inventarios',
            //   tooltip: 'Mis inventarios',
            //   icon: 'bx-package',
            //   expand: false,
            //   children: [
            //     {
            //       link: '/inventory',
            //       name: 'Stock',
            //       tooltip: 'Stock',
            //       icon: 'bx-archive'
            //     },
            //     {
            //       link: '/moves',
            //       name: 'Movimientos',
            //       tooltip: 'Movimientos',
            //       icon: 'bx-transfer-alt'
            //     }
  
            //   ]
            // },
            {
              link: '/',
              name: 'Salir',
              tooltip: 'Salir',
              icon: 'bx-exit',
              children:[]
            }       
          ],
        },
  
        //! Search
        // isSearch: {
        //   type: Boolean,
        //   default: true,
        // },
  
        //! Styles
        bgColor: {
          type: String,
          default: '#303F9F',
        },
        secondaryColor: {
          type: String,
          default: '#1d1b31',
        },
        homeSectionColor: {
          type: String,
          default: '#e4e9f7',
        },
        logoTitleColor: {
          type: String,
          default: '#fff',
        },
        iconsColor: {
          type: String,
          default: '#fff',
        },
        itemsTooltipColor: {
          type: String,
          default: '#e4e9f7',
        },
        searchInputTextColor: {
          type: String,
          default: '#fff',
        },
        menuItemsHoverColor: {
          type: String,
          default: '#fff',
        },
        menuItemsTextColor: {
          type: String,
          default: '#fff',
        },
        menuFooterTextColor: {
          type: String,
          default: '#fff',
        },
      },
      data() {
        return {
          inputpatient:"",
          isOpened: false,
          ids:arrayIds,
          // logo,
          // logoGlya,
          storeprueba:store,
          window: {
              width: 0
          }
        }
      },
      created() {
          // window.addEventListener('resize');
          // this.menuResponsive();
          // this.$store.dispatch("putVaccines")
      },
      destroyed() {
          // window.removeEventListener('resize');
      },
  
      mounted() {
        this.isOpened = this.isMenuOpen
      },
      methods: {

        resetExpandir() {
          this.isOpened = !this.isOpened

          this.$props.menuItems.forEach(item => {
            item.expand = false;
          });
        },
        
        // menuResponsive() {
        //   this.window.width = window.innerWidth;
        //   if (this.window.width < 900) {
        //     this.isOpened=false
        //   } else {
        //     this.isOpened=true
        //   }
        // },
        patientSearch: function(){
          // this.$router.push({ name: 'patients',query:{q:this.inputpatient} }).catch(()=>{})
        }
      },
      computed: {
        cssVars() {
          return {
            // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
            '--bg-color': this.bgColor,
            '--secondary-color': this.secondaryColor,
            '--home-section-color': this.homeSectionColor,
            '--logo-title-color': this.logoTitleColor,
            '--icons-color': this.iconsColor,
            '--items-tooltip-color': this.itemsTooltipColor,
            '--serach-input-text-color': this.searchInputTextColor,
            '--menu-items-hover-color': this.menuItemsHoverColor,
            '--menu-items-text-color': this.menuItemsTextColor,
            '--menu-footer-text-color': this.menuFooterTextColor,
          }
        },
      },
      components: {
        // FormBasicData,
  
      },    
      watch: {
        isOpened() {
          // window.document.body.style.paddingLeft = this.isOpened && this.isPaddingLeft ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody
        }
      }
    }
  </script>
  
  <style scoped>
      .toolbarglya{
          max-height: 64px;
          margin-top: -6px;
          width: 100%;
      }
      .border-avatar{
          border: 1px solid white;
      }
      .top-bar{
        width: 100%;
      }
      @media (max-width: 420px) {
          .fixed-bar {
          position: sticky;
          position: -webkit-sticky; /* for Safari */
          top: 0em;
          z-index: 2;
          }

      } 
  
      .cortina {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0,0.6);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
      }

  /* Google Font Link */
  @import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');
  
  .my-scroll ul {
    padding-left: 0px !important;
  }
  
  .menu-logo {
    width: 30px;
    margin: 0 10px 0 10px;
  }
  
  .sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    min-height: min-content;
    /* overflow-y: auto; */
    width: 50px;
    background: var(--bg-color);
    /* padding: 6px 14px 0 14px; */
    z-index: 99;
    transition: all 0.5s ease;
  }
  
  .sidebar.open {
    width: 250px;
  }
  
  .sidebar .logo-details {
    /* height: 120px; */
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.5s ease;
  }
  
  .sidebar .logo-details .logo_name {
    color: var(--logo-title-color);
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.5s ease;
  }
  
  .sidebar.open .logo-details .icon,
  .sidebar.open .logo-details .logo_name {
    opacity: 1;
  }
  
  .sidebar .logo-details #btn {
    position: absolute;
    top: 20%;
    right: 0;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  
  .sidebar.open .logo-details #btn {
    text-align: right;
  }
  
  .sidebar i {
    color: var(--icons-color);
    height: 60px;
    min-width: 50px;
    font-size: 28px;
    text-align: center;
    line-height: 60px;
  }
  
  .sidebar .nav-list {
    margin-top: 20px;
    /* margin-bottom: 60px; */
    /* height: 100%; */
    /* min-height: min-content; */
  }
  
  .sidebar li {
    position: relative;
    margin: 8px 0;
    list-style: none;
  }
  
  .sidebar li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 3;
    background: var(--items-tooltip-color);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
  }
  
  .sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .sidebar.open li .tooltip {
    display: none;
  }
  
  .sidebar input {
    font-size: 15px;
    color: var(--serach-input-text-color);
    font-weight: 400;
    outline: none;
    height: 50px;
    width: 100%;
    width: 50px;
    border: none;
    border-radius: 12px;
    transition: all 0.5s ease;
    background: #1A237E;
    margin-right: 10px;
  }
  
  .sidebar.open input {
    padding: 0 20px 0 50px;
    width: 100%;
  }
  
  .sidebar .bx-search {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    font-size: 22px;
    background: #1A237E;
    color: var(--icons-color);
  }
  
  .sidebar.open .bx-search:hover {
    background: var(--secondary-color);
    color: var(--icons-color);
  }
  
  .sidebar .bx-search:hover {
    background: var(--menu-items-hover-color);
    color: var(--bg-color);
  }
  
  .sidebar li a, .sidebar li button {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    /* background: var(--bg-color); */
  }
  
  .sidebar li a:hover {
    background: var(--menu-items-hover-color);
  }
  .sidebar li button:hover {
    background: var(--menu-items-hover-color);
  }
  
  .sidebar li a .links_name, .sidebar li button .links_name {
    color: var(--menu-items-text-color);
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
  }
  
  .sidebar.open li a .links_name, .sidebar.open li button .links_name {
    opacity: 1;
    pointer-events: auto;
  }
  
  .sidebar li a:hover .links_name, .sidebar li button:hover .links_name,
  .sidebar li a:hover i, .sidebar li button:hover i {
    transition: all 0.5s ease;
    color: var(--bg-color);
  }
  .sidebar li button:hover .links_name,
  .sidebar li button:hover i {
    transition: all 0.5s ease;
    color: var(--bg-color);
  }
  
  .sidebar li i {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
  }
  
  .sidebar div.profile {
    position: relative;
    height: 60px;
    width: 78px;
    /* left: 0;
      bottom: 0; */
    padding: 10px 14px;
    background: var(--secondary-color);
    transition: all 0.5s ease;
    overflow: hidden;
  }
  
  .sidebar.open div.profile {
    width: 250px;
  }
  
  .sidebar div .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  
  .sidebar div img {
    height: 100px;
    width: 177px;
    object-fit: contain;
    border-radius: 6px;
    margin-right: 0px;
    margin-top: 20px;
  
  }
  
  /* .sidebar div .menu-logo-glya {
  
    width: 50px;
    position: absolute;
    top: -60px;
  
  } */
  
  .sidebar div.profile .name,
  .sidebar div.profile .job {
    font-size: 15px;
    font-weight: 400;
    color: var(--menu-footer-text-color);
    white-space: nowrap;
  }
  
  .sidebar div.profile .job {
    font-size: 12px;
  }
  
  .sidebar .profile #log_out {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: var(--secondary-color);
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 0px;
    transition: all 0.5s ease;
  }
  
  .sidebar.open .profile #log_out {
    width: 50px;
    background: var(--secondary-color);
    opacity: 0;
  }
  
  .sidebar.open .profile:hover #log_out {
    opacity: 1;
  }
  
  .sidebar.open .profile #log_out:hover {
    opacity: 1;
    color: red;
  }
  
  .sidebar .profile #log_out:hover {
    color: red;
  }
  
  .home-section {
    position: relative;
    background: var(--home-section-color);
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    z-index: 2;
  }
  
  .sidebar.open~.home-section {
    left: 250px;
    width: calc(100% - 250px);
  }
  
  .home-section .text {
    display: inline-block;
    color: var(--bg-color);
    font-size: 25px;
    font-weight: 500;
    margin: 18px;
  }
  
  .my-scroll-active {
    overflow-y: auto;
  }
  
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  .my-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 60px);
    scrollbar-width: auto;
    scrollbar-color: #8e98fd #2D1793;
  }
  
  /* Chrome, Edge, and Safari */
  .my-scroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .my-scroll::-webkit-scrollbar-track {
    background: #2D1793
  }
  
  .my-scroll::-webkit-scrollbar-thumb {
    background-color: #8e98fd;
    border-radius: 10px;
    border: 2px solid #2D1793;
  }
  
  .bx1 {
    display: none !important;
  }
  
  .my-scroll .v-application ul,
  .v-application ol {
    padding-left: 0px;
  }
  
  .sidebar li .button-new .showbutton,
  .sidebar li .button-new {
    width: 50px;
    transition: all 0.5s ease;
  }
  
  .sidebar.open li .button-new .showbutton,
  .sidebar.open li .button-new {
    width: 95%;
    transition: all 0.5s ease;
    margin: 3px;
  }
  
  .sidebar li .button-new button span span {
    opacity: 0;
  }
  
  .sidebar.open li .button-new button span span {
    opacity: 1;
  }
  
  .sub-menu {
    width: auto;
    height: auto;
    padding-left: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 0px;
  
  }
  
  .sub-menu>span>ul>li>div {
    height: 30px;
  }
  
  .sub-menu-color {
    background-color: #202c79 !important;
  
  }
  
  .bx-padding {
  
    padding-left: 20px;
  }
  
  .sidebar li .sub-menu-item:hover {
    background: #5144e33d;
  }
  
  .sidebar li .sub-menu-item:hover .links_name,
  .sidebar li .sub-menu-item:hover i {
    transition: all 0.5s ease;
    color: #EBEBEE;
  }
  
  .chevron-position{
  
    position: absolute;
  left: 200px;
  }
  
  .logo-gdi{
    max-width: 30px;
    margin-top: 15px;
    margin-left: 10px;
  }
  .profile{
    border-radius: 50px;
    margin-top: 0 !important;
    width:300px;
      height:300px;
      border-radius:150px;
  }
  @media (min-width: 420px) {
.toolbarglya{
  padding-left: 50px;
}
  }

  
  @media (max-width: 420px) {
  
    .sidebar li .tooltip {
      display: none;
    }
  
    .bx1 {
      display: block !important;
      margin-top: -50px;
      margin-bottom: 20px;
      margin-left: -5px;
      z-index: 1;
      width: 30px;
      background: #2D1793 !important;
      color: #ffffff !important;
    }
  
    .sidebar {
      width: 0px;
    }


  
    .bx-menu {
      width: 200px;
      left: -80px;
      margin-top: 8px;
    }
  
    /* body {
      padding-left: 0px !important
    }
   */
    
  }
  </style>