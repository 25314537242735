<template>
    <div class="bg-welcome d-flex align-center">
        <v-row class="d-flex justify-center animation">
            <v-col cols="12">
                <img src="../assets/glyawhite1.svg" class="img-glya pb-5" >
            </v-col>
            <v-col cols="10" sm="8">
                <img src="../assets/img-welcome-01.svg" class="mb-5">
                <v-col cols="8" class="mx-auto">
                    <v-btn @click="loginReq()" rounded class="green font-weight-bold" color="white--text" block large>
                        Empezar
                    </v-btn>
                </v-col>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
        name: 'FormBasicData',
        data:function() {
            return {}
        },
        methods:
        {
            loginReq: function(){
                this.$router.push({name:'Login'})
            }
        },
        created() {
            this.$store.commit('SET_LAYOUT', 'ClearLayout')
        },
    }
</script>
    
<style scoped>


    .animation{
        animation: slideIn 1.5s ease-in-out forwards;
        margin: auto;
        max-width: 800px;
    }
    .bg-welcome{
        background: url(../assets/bg-blue.svg) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        height: 100vh;
        position: relative;
        overflow-y:scroll;
        overflow-x:scroll
    }
    .img-glya{
        width: 50%;
        max-width: 250px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .welcome-text{
        font-size: 50px;
        color: white;
        font-family: 'Roboto-Regular';
        text-align: center;
        line-height: 40px;

    }


    @keyframes slideIn {
        0% {
            transform: translateX(500px) ;
        }
        100% {
            transform: translateX(0px) ;
        }
    }
    
    @media (max-width: 576px) {

        .welcome-text{
            font-size: 30px;
        }
        .btn-welcome{
            font-size: 20px;
        }
    }
</style>
