<template>

  <div>
    <v-dialog transition="dialog-bottom-transition" v-model="dialog_order" max-width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="blue" x-large>mdi-map-marker</v-icon>
      </template>
      <template v-slot:default>

        <l-map
        :zoom="zoom"
        :center="center"
        style="height: 500px; width: 100%"
        @ready="resize()"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
      <!-- Use default icon -->
      
      <!-- Create HTML icon (divIcon) by providing content inside the l-icon tag -->
      <l-marker :lat-lng="location_1">
        <l-icon
          :icon-anchor="staticAnchor"
          class-name="someExtraClass"
        >
          <div class="headline">
            {{ customText }}
          </div>
          <img src="../../assets/place_black.svg">
        </l-icon>
      </l-marker>
    </l-map>
    <v-card>
      <v-card-actions class="d-flex justify-space-between">
          <v-btn @click="dialog_order = false" class="pl-5 pr-5 white--text" rounded depressed color="red" small>Cerrar mapa
              <v-icon right dark> mdi-close</v-icon>
          </v-btn>
      </v-card-actions>
  </v-card>
    </template>
      </v-dialog>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { latLng, icon } from "leaflet";

export default {
  name: "Icon-my",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data() {
    return {
      dialog_order:false,
      zoom: 13,
      location_1:[this.$store.state.sessionLocation.latitude, this.$store.state.sessionLocation.longitude],
      center: latLng(this.$store.state.sessionLocation.latitude,this.$store.state.sessionLocation.longitude),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      icon: icon({
        iconUrl: "../../assets/place_black.svg",
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      staticAnchor: [16, 37],
      customText: "",
      iconSize: 64
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  methods:
  {
    resize()
    {
      setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 250);
    },
  }
};
</script>

<style>
.someExtraClass {
  /* background-color: aqua; */
  padding: 10px;
  /* border: 1px solid #333; */
  /* border-radius: 0 20px 20px 20px; */
  /* box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2); */
  text-align: center;
  width: 40px !important;
  height: auto !important;
  margin: 0 !important;
}
.someExtraClass .headline{

}
</style>
