<template>
    <div> 
        <v-container class="head-form ma-0 purple_glya_dark">
            <v-card class="mx-auto mt-1 mb-2" color="transparent" elevation="0">
                <v-list-item class="d-flex align-start pa-0">
                    <v-avatar size="70px"  class="ma-3">
                        <img alt="Femenino" v-if="this.$store.state.presentTask.patient.sex == 'F'" src="../../assets/perfil-f.jpg">
                        <img alt="Femenino" v-if="this.$store.state.presentTask.patient.sex == 'M'" src="../../assets/perfil-m.jpg">
                        <img alt="Femenino" v-if="this.$store.state.presentTask.patient.sex == '' || patient.sex == 'Indeterminado'" src="../../assets/perfil.jpg">
                    </v-avatar>
                    <v-list-item-content class="pa-1 white--text">
                        <span class="patient-name">{{this.$store.state.presentTask.patient.full_name}} <v-chip class="ma-2" color="white" outlined> {{this.$store.state.presentTask.patient.status}}</v-chip></span>
                        <v-list-item-content class="pa-0 mt-1 patient-info">
                            <p class="mb-1 white--text">
                                <span class="pr-4"> {{this.$store.state.presentTask.patient.id_type}}  {{this.$store.state.presentTask.patient.nid}} / {{this.$store.state.presentTask.patient.nationality}} | Grupo Sanguineo: 
                                    <span v-if="this.$store.state.presentTask.patient.blood_type == '' || this.$store.state.presentTask.patient.rh_factor == ''"><v-chip class="ma-2 ml-0 v-chip-s" color="white" x-small outlined> Dato incompleto</v-chip></span>
                                    <span v-else>{{this.$store.state.presentTask.patient.blood_type}}{{this.$store.state.presentTask.patient.rh_factor}} </span>
                                </span>
                            </p>
                            <p class="mb-1 white--text">
                                <span> 
                                    <span v-if="this.$store.state.presentTask.patient.sex == 'M'">Masculino</span>
                                    <span v-else-if="this.$store.state.presentTask.patient.sex == 'F'">Femenino</span>
                                    <span v-else>Indeterminado</span>, {{this.$store.state.presentTask.patient.yearsAge}} años {{this.$store.state.presentTask.patient.monthsAge}} meses
                                </span>                              
                            </p>    
                        </v-list-item-content>
                    </v-list-item-content>        
                </v-list-item>
            </v-card>
            <v-col height="120px"></v-col>

        </v-container>      
    </div>
</template>
<script>
    
    export default {
    components:{
    
    },
    data() {
        return {
            "patient": {
                "religion": null,
                "names": "JUAN FERNANDO",
                "surnames": "BARON LOPEZ",
                "fullName": "JUAN FERNANDO BARON LOPEZ",
                "nid": "79601276",
                "status": "Activo",
                "id_type": "CC",
                "birthdate": "1992-05-06",
                "sex": "M",
                "email": "juan@juan.com",
                "phone": "3102622222",
                "phone_2": null,
                "regime": "contributivo",
                "address": "Carrera 13 20-20",
                "current_residence":"Bogotá",
                "blood_type": "A",
                "rh_factor": "+",
                "yearsAge": 30,
                "monthsAge": 9,
                "deceased": false,
                "social_level": null,
                "civil_status": null,
                "scholarship": null,
                "contributor": true,
                "displaced": false,
                "ethnic_group": 7,
                "external_suburb": null,
                "id": "640169903379530ee361a3cf",
                "full_name": "JUAN FERNANDO BARON LOPEZ",
                "birthdate_format": "06 de May de 1992",
                }
            }
        }
    }

</script>

<style>
    .head-form{
        min-height: 100px;
    }
    .patient-name{
        font-family: 'Roboto-Black', sans-serif !important;
        font-size: 19px;
        letter-spacing: 1px;
    }
    .patient-name .v-chip{
    padding: 5px 10px;
    height: 20px;
    }
    .v-chip-s .v-chip__content {
    font-size: 13px;
    }

</style>