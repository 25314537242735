<template>
    <div>
      <div class="negativetop-progress">
        <v-row class="pa-0 ma-0 d-flex justify-center">
          <v-col cols="12" sm="10" md="6" class="progress-bar">
            <ul>
              <li  v-for="item in items" :key="item" :class="{ active: pageForm >= item }">{{item}}</li>
            </ul>
            <div class="line-progress"></div>
          </v-col>
        </v-row>
      </div>
      <v-container>

        <FormMed v-if="this.$store.state.taskOk"></FormMed>
        <!-- <FormFailed></FormFailed> -->

      </v-container>
      
      <v-container class="mb-5 pb-5">
        <div class="navigation_form">
            <v-col cols="12" class="d-flex justify-space-between">
              <v-btn v-if="pageForm > numerationStart" class="white--text" rounded color="grey" @click="backPage">
                <v-icon left>mdi-arrow-left</v-icon>Anterior
              </v-btn>
              <div></div>
              <v-btn v-if="pageForm < numerationEnd" class="white--text" rounded color="purple_glya" @click="nextPage">
                Siguiente <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn v-if="pageForm == numerationEnd" class="white--text" rounded color="green" @click="sendForm()">
                Enviar <v-icon right>mdi-check-circle-outline</v-icon>
              </v-btn>
            </v-col>
        </div>
      </v-container>

    </div>
</template>
<script>
    import FormMed from "@/components/forms/FormMed.vue";
    //import FormFailed from "@/components/forms/FailedForm.vue";
    import store from "@/store/index.ts";

    export default {
      
      components:{
        FormMed,
        //FormFailed
      },
      data() {
        return {
            numerationStart:1,
            numerationEnd:7,
            pageForm:1,
            items:[1,2,3,4,5,6,7]
        }
      },
      computed:{
        page() {
          return store.state.pageForm;
        },
      },
          created() {
              this.$store.commit('SET_LAYOUT', 'LayoutForm')
          },
          methods: {
            nextPage() {
              this.pageForm++
              const newPage = this.pageForm;
              console.log(newPage);
              store.commit("setPage", newPage);
            },
            backPage() {
              this.pageForm--
              const newPage = this.pageForm;
              console.log(newPage);
              store.commit("setPage", newPage);
            },
            sendForm()
            {
              let indexTask=0;
              this.$store.state.tasks['Activas'].forEach((task,index)=>
              {
                if(task.encounter.id===this.$store.state.presentTask.id)
                {
                  indexTask=index;
                }
              })
              this.$store.state.tasks['Activas'].splice(indexTask,1);
              this.$store.state.presentTask.status="Finalizada";
              this.$store.state.tasks['Finalizadas'].push({'encounter':this.$store.state.presentTask})
              this.$store.state.presentTask={};
              this.$router.push('/main');
            }
          },
      };
  </script>

<style>
  .negativetop-progress {
    margin-top: -50px;
    margin-bottom: 10px;
  }
  .negativetop-progress ul{
  padding:0px 20px;
}

  .line-progress{
    border-bottom: 1px solid white;
    width: 100%;
    margin-top: -10px;
  }

  .progress-bar ul{
      display: flex;
      justify-content: space-between;
      background-color: none;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    
    .progress-bar li {
      width: 22px;
      text-align: center;
      color: #888;
      background-color: #f9f9f9;
      font-size: 15px;
        border-radius: 10px;
    }
    
    .progress-bar li.active {
      background-color: #4CAF50;
      color: white;
    }
    .navigation_form{
      position: absolute;
      bottom: 0px;
      width:98%;
      padding-right: 20px;

    }
    </style>