<template>
    <VueSignaturePad
        id="signature"
        width="100%"
        height="300px"
        ref="signaturePad"
        :options="{onEnd}"
        
      />
</template>

<script>

export default{

    model: {
            prop: "value",
            event: "change"
        },
    data() {
        return{
            value:{'state':Boolean, 'data':""}
        }
    },
    
    methods:
    {
      clearSignature()
      {
        this.$refs.signaturePad.clearSignature();
        this.saveSignature();
      },
      saveSignature()
      {
        this.value=this.$refs.signaturePad.saveSignature();
        this.$emit('change', this.value);
      },
      onEnd() {
        this.saveSignature();
      },
      loadSignature(signatureData)
      {
        this.$refs.signaturePad.fromDataURL(signatureData);
      }
    }
}
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>>