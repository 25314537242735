<template>
    <div class="bg-login d-flex align-center">
        <v-row class="d-flex justify-center negative-margin-80">
            <v-col cols="6" sm="4">
                <img max-width="300" src="../assets/gdi_azul.svg" class="logo-gdi">
            </v-col>
            <v-col cols="12">
                <v-col cols="10" sm="8" class="mx-auto">
                    <BaseInput type="email" placeholder="Usuario" label="Usuario" v-model="userForm" outlined myclass="login"></BaseInput>
                    <BaseInput type="password" placeholder="Contraseña" label="Contraseña" v-model="passwordForm"  outlined myclass="login"></BaseInput>
                    <p>¿No tienes cuenta? <span class="orange--text">Crear una cuenta</span></p>
                    <v-btn @click="loginReq()" rounded class="blue_glya_dark mt-5" color="white--text" block size="large">
                    Iniciar sesión
                    </v-btn>
                </v-col>
            </v-col>
            <div class="copy d-flex align-end justify-center">
                <div>
                    <v-col cols="12" class="pb-0 glya-copy">
                        <img src="../assets/glya_azul.svg" class="pb-0">
                    </v-col>
                    <v-col cols="12">
                        <p class="text-center pt-0">Derechos reservados 2023</p>
                    </v-col>
                </div>
            </div>
        </v-row>
    </div>
</template>

<script>
    import BaseInput from '@/components/forms_items/BaseInput.vue';
    export default ({
        components: {BaseInput},
        data: function() {
            return {
                userForm:"",
                passwordForm:""
            };
            },
        methods:
        {
            loginReq()
            {
                let userCredentials={
                    'user_email': this.userForm,
                    'user_password': this.passwordForm
                }

                this.$store.state.menuView=true;
                this.$store.dispatch('getLocation').then((res)=>
                {
                    this.$store.state.sessionLocation={latitude:res.coords.latitude,longitude:res.coords.longitude};
                    this.irPrincipal();
                }).catch((err)=>
                {
                    console.log(err);
                    this.$store.state.sessionLocation={latitude:4.649880683593793,longitude:-74.0759220345954};
                    this.irPrincipal();
                });
                // this.$store.dispatch('login',userCredentials).then((res)=>
                // {
                //     console.log(res);
                //     this.$store.state.menuView=true;
                //     this.$store.dispatch('getLocation').then((res)=>
                //     {
                //         this.$store.state.sessionLocation={latitude:res.coords.latitude,longitude:res.coords.longitude};
                //     }).catch((err)=>
                //     {
                //         console.log(err);
                //         this.$store.state.sessionLocation={latitude:4.649880683593793,longitude:-74.0759220345954};
                //     });
                //     this.irPrincipal();
                // }).catch((err)=>
                // {
                //     console.log(err);
                //     this.$store.state.menuView=true;
                //     this.$store.dispatch('getLocation').then((res)=>
                //     {
                //         this.$store.state.sessionLocation={latitude:res.coords.latitude,longitude:res.coords.longitude};
                //     }).catch((err)=>
                //     {
                //         console.log(err);
                //         this.$store.state.sessionLocation={latitude:4.649880683593793,longitude:-74.0759220345954};
                //     });
                //     this.irPrincipal();
                // });
            
            },
            
            irPrincipal()
            {
                this.$router.push('/main');
            },


        },
        created() {
            this.$store.commit('SET_LAYOUT', 'ClearLayout')
        },
    });
    
</script>

<style scoped>
    .bg-login{
        background: url(../assets/bg-login.png) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        height: 100vh;
        overflow-y:scroll;
        overflow-x:scroll;
        display: flex;
        flex-direction: column;
    }
    .logo-gdi{
        padding-top: 100px;
    }
    
    .copy{
        flex: 1;
    } 
    .negative-margin-80{
        margin-top: -80px;
        margin: auto;
        max-width: 800px;
        width: 100%;
    }
    .glya-copy{
        text-align: center;
    }
    .glya-copy img{
        width: 45%;
        max-width: 200px;
    }
</style>
