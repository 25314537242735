import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Welcome from "../views/WelcomePage.vue";
import AboutView from "../views/AboutView.vue";
import Login from "../views/LoginPage.vue";
import Main from "../views/MainPage.vue";
import Form from "../views/ActivityForm.vue";
import FormValidation from "../views/ActivityValidation.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
  },
  {
    path: "/form",
    name: "Form",
    component: Form,
  },
  {
    path: "/validation",
    name: "Validation",
    component: FormValidation,
  },
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
