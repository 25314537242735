<template>
    <div> 
        <v-container>

            <div v-if="pageForm == 1">
                <h1 class="purple_glya_dark--text mb-3">Control de signos vitales</h1>
                <v-row class="mb-5">
                    <v-col cols="6" sm="3" v-for="(sign, index) in taskForm.vitals.slice(1)" :key="index">
                        <v-card elevation="0" :color="state(sign.status)" class="rounded-card">
                            <h1 class="pl-3 pt-1 white--text">{{sign.type}}</h1>
                            <v-col class="d-flex justify-center flex-column pt-0">
                                <v-icon size="100" color="white">{{sign.icon}}</v-icon>
                                <p class="white--text text-center pa-0 mb-0">{{getValueVital(index)}} <span> {{sign.unit}} </span></p>
                            </v-col>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mt-5">
                    <v-col class="d-flex flex-row justify-space-between"  v-for="(sign, index) in taskForm.vitals" :key="index" cols="12" sm="8">
                        <div>
                            <p><v-icon large class="mr-5">{{sign.icon}}</v-icon>{{sign.name}}</p>
                        </div>
                        <div class="input-vital">
                            <BaseInput label="" outlined myclass="element" v-model="taskForm.vitals[index].res" @input="saveChange()"></BaseInput>
                        </div>
                    </v-col>

                </v-row>
            </div>
            <div v-if="pageForm == 2">
                <h1 class="purple_glya_dark--text">Verificación</h1>
                <ul v-for="(question, index) in taskForm.rightTen.verification" :key="('P'+pageForm)+index">
                    <li class="style-list">
                        <v-row d-flex justify-space-between>
                            <v-col class="mt-2 mb-0">
                                <p class="mb-2">{{question.question}}</p>
                            </v-col>
                            <v-col class="width-switch mt-2 mb-0">
                                <BaseSwitch v-model="taskForm.rightTen.verification[index].res" @change="saveChange()"></BaseSwitch>
                            </v-col>
                        </v-row>
                        <v-divider class="dotted"></v-divider>
                    </li>
                </ul>
            </div>
            <div v-if="pageForm == 3">
                <h1 class="purple_glya_dark--text mb-3">Verificación</h1>
                <v-row>
                    <v-col cols="12" sm="6" class="pb-0">
                        <BaseSelect label="Tipo de acceso venoso" outlined  myclass="element" :opciones="$store.state.venousAccessTypes" v-model="taskForm.venousAccessType" @change="saveChange()"></BaseSelect>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                        <v-row d-flex justify-space-between>
                            <v-col>
                                <p>Signo de infeccion local</p>
                            </v-col>
                            <v-col class="width-switch">
                                <BaseSwitch v-model="taskForm.signsLocalInfection" @change="saveChange()"></BaseSwitch>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0" v-if="taskForm.signsLocalInfection">
                        <BaseTextArea type="text" label="¿Cual?" rows="1"  outlined  myclass="element" v-model="taskForm.localInfection"  @input="saveChange()"></BaseTextArea>
                    </v-col>
                    <v-col cols="12" class="pb-0 pt-0">
                        <v-row d-flex justify-space-between>
                            <v-col>
                                <p>¿Se realiza cambio de venipunción?</p>
                            </v-col>
                            <v-col class="width-switch">
                                <BaseSwitch v-model="taskForm.changeVenipuncture"  @change="saveChange()"></BaseSwitch>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
    
                <h1 class="purple_glya_dark--text mb-3 mt-3">Condiciones ambientales de almacenamiento</h1>
                <ul v-for="(question, index) in taskForm.environmentalConditions.environmentalConditions" :key="index">
                    <li class="style-list">
                        <v-row d-flex justify-space-between>
                            <v-col class="mt-2 mb-0">
                                <p class="mb-2">{{question.question}}</p>
                            </v-col>
                            <v-col class="width-switch mt-2 mb-0">
                                <BaseSwitch  v-model="taskForm.environmentalConditions.environmentalConditions[index].res"  @change="saveChange()"></BaseSwitch>
                            </v-col>
                        </v-row>
                        <v-divider class="dotted"></v-divider>
                    </li>
                </ul>
                <v-col cols="12" class="pt-5 pb-0">
                    <BaseTextArea type="text" label="Observaciones" rows="2"  outlined  myclass="element"  v-model="taskForm.observationsA"  @input="saveChange()"></BaseTextArea>
                </v-col>
            </div>
            <div v-if="pageForm == 4">
                <h1 class="purple_glya_dark--text mb-3">Administración de medicamento</h1>
                <p>Verifique muy bien el medicamento a administrar de acuerdo a las siguentes indicaciones</p>
                <v-simple-table class="table-medicine mb-5">
                    <template v-slot:default>
                        <tbody>
                            <tr
                            v-for="(item, index) in taskForm.medicine" :key="index">
                            <th>{{ item.indication }}</th>
                            <td>{{ item.res }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-row class="mt-5">
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                        <BaseInput label="Registro INVIMA" outlined myclass="element" v-model="this.$store.state.presentTask.medication_administrations[0].invima_register"  @input="saveChange()"></BaseInput>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                        <BaseInput label="Lote" outlined myclass="element" v-model="this.$store.state.presentTask.medication_administrations[0].date_register"  @input="saveChange()"></BaseInput>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                        <BaseDate label="Fecha de vencimiento" outlined  myclass="element" v-model="this.$store.state.dateLimit"  @blur="saveChange()"></BaseDate>
                    </v-col>
    
                </v-row>
            </div>
            <div v-if="pageForm == 5">
                <h1 class="purple_glya_dark--text mb-3">Descripción de la actividad</h1>
                <BaseTextArea type="text" label="Describa la actividad" rows="4"  outlined  myclass="element" v-model="taskForm.biosecDescription"  @input="saveChange()"></BaseTextArea>
                <h1 class="purple_glya_dark--text">Bioseguridad y desposición de residuos</h1>
                <ul v-for="(question, index) in taskForm.bioseg" :key="index">
                    <li class="style-list">
                        <v-row d-flex justify-space-between>
                            <v-col class="mt-2 mb-0">
                                <p class="mb-2">{{question.question}}</p>
                            </v-col>
                            <v-col class="width-switch mt-2 mb-0">
                                <BaseSwitch v-model="taskForm.bioseg[index].res"  @change="saveChange()"></BaseSwitch>
                            </v-col>
                        </v-row>
                        <v-divider class="dotted"></v-divider>
                    </li>
                </ul>
            </div>
            <!-- <div v-if="pageForm == 6">
                <h1 class="purple_glya_dark--text mb-3">Ultima aplicación</h1>
                <v-simple-table class="table-medicine mb-5">
                    <template v-slot:default>
                        <tbody>
                            <tr
                            v-for="(item, index) in lastApplication" :key="index">
                            <th>{{ item.indication }}</th>
                            <td>{{ item.res }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <h1 class="purple_glya_dark--text mb-3">Devoluciones</h1>
                <v-row class="mt-3">
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <BaseInput label="Jeringas" outlined myclass="element"></BaseInput>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <BaseInput label="Viaflex líquidos" outlined myclass="element"></BaseInput>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <BaseInput label="catéteres IV" outlined myclass="element"></BaseInput>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <BaseInput label="Tapones heparinizados" outlined myclass="element"></BaseInput>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <BaseInput label="Paños antisépticos" outlined myclass="element"></BaseInput>
                    </v-col>
                </v-row>
                <h1 class="purple_glya_dark--text mb-3">Otros</h1>
                <v-row class="mt-3">
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <BaseInput label="Número de equipo" outlined myclass="element"></BaseInput>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <BaseInput label="Reacciones adversas" outlined myclass="element"></BaseInput>
                    </v-col>
                </v-row>
            </div> -->
            <div v-if="pageForm == 6">
                
                <h1 class="purple_glya_dark--text mb-3">Firma</h1>
                <p>Yo, <b>{{ this.$store.state.presentTask.patient.full_name}}</b> o su cuidador/responsable, confirmo que se ha realizado el procedimiento descrito: </p>
                <ul>
                    <li class="style-list"><p>{{ this.$store.state.presentTask.orders[0].order.cup_name }}: {{ this.$store.state.presentTask.medication_administrations[0].medication_name }}</p></li>
                </ul>
                
                <v-col cols="12" class="d-flex flex-column">
                    
                    <signature ref="signaturePad" v-model="taskForm.patientSignature"  @change="saveChange()"></signature>
                    <v-col cols="6" class="d-flex justify-center mx-auto firma pt-1">
                        <p class="pt-0 mt-0">FIRMA</p>
                    </v-col>

                    <v-col width="200" class="d-flex justify-center">
                        <v-btn color="red" class="white--text"  rounded small @click="clearSignature">Borrar firma</v-btn>
                    </v-col>
                </v-col>
                
            </div>
            <div v-if="pageForm == 7">
                <v-row class="d-flex justify-center">
                    <v-col cols="8" class="text-center">
                        <v-icon size="100" color="green">mdi-check-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="8" class="text-center">
                        <h1 class="green--text">Se completó con exito la visita y se ejecuto el procedimiento</h1>
                    </v-col>
                    <v-col  cols="12" class="text-center">
                        <p>Yo, <b>Diana Villa</b> declaro que la informacion consignada es veraz y de acuerdo a la orden estipulada</p>
                    </v-col>
                    <v-col cols="6">

                    </v-col>
                </v-row>
            </div>

        </v-container>
    </div>
</template>
<script>
    import BaseSwitch from "@/components/forms_items/BaseSwitch.vue";
    import BaseDate from "@/components/forms_items/BaseDate.vue";
    import BaseInput from "@/components/forms_items/BaseInput.vue";
    import BaseSelect from "@/components/forms_items/BaseSelect.vue";
    import BaseTextArea from "@/components/forms_items/BaseTextArea.vue";
    import SignaturePad from "@/components/SignaturePad.vue"
    import store from "@/store/index.ts";

    export default {
        components: {
            BaseDate,
            BaseInput,
            BaseSelect,
            BaseTextArea,
            BaseSwitch,
            'signature':SignaturePad
        },
        data() {
            return {

                taskForm:this.getTaskForm(),
                lastApplication:[
                    {indication:"Medicamento",res:""},
                    {indication:"Concentración",res:""},
                    {indication:"Dosis",res:""}
                ]
            }
        },
        methods:{
            state: function(estado){             
                switch (estado){
                    case 'risky':
                    return "yellow" ;              
                    case 'critical':
                    return "red" ;               
                    case 'stable':
                    return "green" ; 
                    default:
                    return "grey lighten-2";                   
                }
            },
            clearSignature()
            {
                this.$refs.signaturePad.clearSignature();
            },
            getValueVital(index)
            {
                if(index==0)
                {
                    return (this.taskForm.vitals[0].res+'/'+this.taskForm.vitals[1].res)
                }else
                {
                    return this.taskForm.vitals[index+1].res
                }
            },
            saveChange()
            {
                this.$store.dispatch('saveInStorage',{'name':'taskForm'+this.$store.state.presentTask.id,'data':JSON.stringify(this.taskForm)});
            },
            getTaskForm()
            {
                if(this.$store.state.taskFormTemp!=="")
                {
                    return this.$store.state.taskFormTemp;
                }else
                {
                    return {
                            taskOk:this.$store.state.taskOk,
                            careGiverData:this.$store.state.careGiverData,
                            signsLocalInfection:false,
                            localInfection:"",
                            venousAccessType:0,
                            changeVenipuncture:false,
                            rightTen:
                            {
                                verification:
                                [
                                {question:"¿Paciente correcto?",res:false},
                                {question:"¿Medicamento correcto?",res:false},
                                {question:"¿Dosis correcta?",res:false},
                                {question:"¿Vía correcta?",res:false},
                                {question:"¿Hora correcta?",res:false},
                                {question:"¿Registro correcto?",res:false},
                                {question:"¿Informó al paciente?",res:false},
                                {question:"¿Interacción de medicamento?",res:false},
                                {question:"¿Indagó sobre alergias?",res:false},
                                {question:"¿Realizo lavado de manos?",res:false},
                                ],
                            },
                            environmentalConditions:
                            {
                                environmentalConditions:
                                [
                                {question:"¿El medicamento está protegido de la humedad y el calor?",res:false},
                                {question:"¿Los medicamentos y/o insumos estan sellados?",res:false},
                                {question:"¿El lugar de almacenamiento cuenta con ventilación natural?",res:false},
                                {question:"¿Está sin contacto directo al piso?",res:false},
                                {question:"¿Están alejados de sitios contaminados?",res:false},
                                {question:"¿Cuenta con iluminación, que permita su facil ubicación?",res:false},
                                ]
                            },
                            observationsA:"",
                            medicine:
                            [
                                {indication:"Medicamento",res:this.$store.state.presentTask.medication_administrations[0].medication_name},
                                {indication:"Concentración",res:this.$store.state.presentTask.medication_administrations[0].concentration},
                                {indication:"Dosis",res:this.$store.state.presentTask.orders[0].order.dose+' '+this.$store.state.presentTask.orders[0].order.unity},
                                {indication:"Vía",res:"Intravenosa"},
                                // {indication:"Dilución",res:""},
                                // {indication:"Tiempo de infusión",res:""},
                                // {indication:"cadena de frio",res:""},
                            ],
                            bioseg:[
                                {question:"¿Ejecutó el lavado de manos en los 5 momentos?",res:false},
                                {question:"¿segregó de forma segura los elementos cortopunzantes en el guardián?",res:false},
                                {question:"¿Segregó de forma segura los farmacos parcialmente consumidos?",res:false},
                                {question:"¿Segregó de forma segura los desechos biosanitorios en bolsa roja?",res:false},
                            ],
                            biosecDescription:"",
                            patientSignature:{},
                            vitals:[
                                {name:"Tensión arterial sistólica",type:"PAS/PAD",icon:"mdi-heart-pulse",res:"",unit:"mmHg",status:"risky"},
                                {name:"Tensión aterial diastolica",type:"PAS/PAD",icon:"mdi-heart-pulse",res:"",unit:"mmHg",status:"risky"},
                                {name:"Frecuencia cardíaca",type:"FC",icon:"mdi-pulse",res:"",unit:"lpm",status:"stable"},
                                {name:"Frecuencia respiratoria",type:"FR",icon:"mdi-lungs",res:"",unit:"rpm",status:"critical"},
                                {name:"Temperatura corporal",type:"Temp",icon:"mdi-thermometer",res:"",unit:"°C",status:""},
                            ]
                        }
                }
            }
        },
        computed:{
            pageForm() {
                return store.state.pageForm;
            },
        },
    };
</script>
<style>
    .width-switch{
        max-width: 100px;
    }
    .dotted{
        border-style: dotted;
    }
    .style-list{
        list-style: disc;
        color: #6f6f6f;
    }
    .table-medicine tr th{
        border: 1px solid #3948ab9b;
        background: #9fabf44d;
        color: #3949AB;
        width: 150px;
    }
    .table-medicine tr td{
        border: 1px solid rgb(204, 204, 204)

    }
    .rounded-card{
        border-radius: 25px !important;
    }
    .input-vital{
        max-width: 80px;
    }
    .firma{
        border-top: 1px solid black;
        margin-top: -50px;
    }
</style>