
import Vue from 'vue';
import {mapState} from 'vuex'
import MainLayout from '@/components/layout/MenuSidebar.vue'
import ClearLayout from '@/components/layout/LayoutClear.vue'
import LayoutForm from '@/components/layout/LayoutForm.vue'

export default Vue.extend({
  name: 'App',

  data: () => ({

    // layout: 'MainLayout'

  }),
  components:{
      MainLayout,
      ClearLayout,
      LayoutForm
    },
    computed:mapState (['layout'])
    
});
