<template>
    <div cols="12" :class="margin">
        <v-switch class="switchglya" :color="color" v-model="checkBox" @change="updateValue"
            :label="checkBox == false || checkBox == undefined ? negative : positive"></v-switch>
    </div>
</template>
<script>
export default {
    name: "BaseSwitch",   
    props: {
        color:{
            type:String,
            default: "primary"
        },
        label: {
            type: String
        },
        negative:{
            type: String,
            default:"No",
        },
        positive: {
            type: String,
            default:"Sí",
        },
        margin: {
            type: String,
            default:"",
        },
        element: {
            type: Boolean,
            default: false
        },
    },
    model: {
        prop: "value",
        event: "change"
    },
    data() {
        return {
            checkBox:this.$attrs.value
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('change', value);
        },
    },     
    components: {
    },
}
</script>
<style>
</style>
