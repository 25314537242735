<template>
    <div class="inputglya">
        <v-menu>
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                :label="label_show"
                ref="menu"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @input="updateValue"
                v-on="on"
                v-model="date"
                :outlined="outlined"
                :class="myclass"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                :active-picker.sync="activePicker"
                :max="limitDateMax"
                :min="limitDateMin"
                @input="updateValue"
                locale="es"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>

    // import { validationMixin } from 'vuelidate'
    // import { required } from 'vuelidate/lib/validators'

    export default {

        // props:["value","label"],
        props:{
            values: {}, label: {}, outlined:{default:false}, myclass:{},
            validador: {
                default: 'none'
            },
            limitType: {default:"none"}
        },
        components: { },

        model:{
            prop:"value",
            event:"blur"
        },
        mounted() {
            this.eval_errors();
        },

        data() {
            return {
                activePicker: null,
                date: this.$attrs.value,
                menu: false,
                // fromvuex: this.$store.state.validations2[this.$props.validador],
                id: Math.floor(Math.random() * 1000000),
                minl: 5,
                showlabel: false
            };
        },
        watch: {
            menu (val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
        },
        methods: {
            updateValue(value) {
                this.$emit('blur', value);
                this.eval_errors()
            },
            set_label: function () {
                this.showlabel = true
                return this.showlabel
            },
            // eval_errors: function () {
            //     if (this.nameErrors.length == 0) {
            //         this.$store.dispatch("puterror", { [this.id]: 0 })
            //     } else {
            //         this.$store.dispatch("puterror", { [this.id]: 1 })
            //     }
            // }
        },
        computed: {
            // eval_required_show: function () {
            //     return this.$store.state.show_required
            // },
            label_show: function () {
                let label = this.$props.label
                if (this.showlabel) {
                    label = `${this.$props.label}${'*'}`
                }
                return label
            },
            // nameErrors() {
            //     let errors = []
            //     if (!this.$v.date.required && this.fromvuex['required'] !== undefined) {
            //         if (this.eval_required_show) {
            //             errors.push('¡El campo es requerido!')
            //         } else {
            //             errors.push(' ')
            //         }
            //         this.set_label()                    
            //     }
            //     return errors
            // },
            limitDateMin() {
                let limit = '1890-01-01' 
                if (this.$props.limitType == 'expiration') {
                    limit = new Date(Date.now()).toISOString().substr(0,10)                  
                } 
                return limit
            },
            limitDateMax() {
                let limit = '2300-01-01'
                if (this.$props.limitType == 'birthdate') {
                    limit = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
                } 
                return limit
            }
        },
        // mixins: [validationMixin],
        validations: function () {
            if (this.minl > 0) {
                return {
                    date: eval(this.fromvuex)
                }
            }
            return {
                // x: {
                //     required
                // }
            }
        }
    }
</script>

<style>

</style>