<template>
    <div>

        <v-dialog transition="dialog-bottom-transition" v-model="dialog_order" max-width="600" persistent>
            <template v-slot:activator="{ on, attrs }">
                <!-- <v-btn v-bind="attrs" v-on="on"> -->
                    <v-card class="mx-auto rounded-lg mb-3" cols="12" outlined v-bind="attrs" v-on="on">
                        <v-card-text class="pa-3">
                            <v-row>
                                <v-col cols="12" sm="3" class=" pa-0 date-activity white--text d-flex align-center" :class="state(task.status)">
                                    <v-col>
                                        <p class="white--text">{{new Date(task.schedule_date).toLocaleDateString()}}</p>
                                        <p class="white--text">{{task.schedule_hour}}</p>
                                        <h5 class="mt-2">{{task.status}}</h5>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" sm="9" class="info-activity">
                                <v-row class="mb-0">
                                    <h3 class="pl-3 mt-2">{{task.patient.fullName}}</h3>
                                    <v-chip class="ma-2" color="success" outlined> {{task.patient.yearsAge}} años</v-chip>
                                </v-row>
                                <p> <v-icon > mdi-home-outline </v-icon> {{task.patient.address}} {{task.patient.current_residence}} </p>
                                <v-row>
                                    <p class="pl-3 mb-0"> <v-icon > mdi-phone-outline </v-icon>  {{task.patient.phone}}</p>
                                    <p class="pl-3"> <v-icon > mdi-email-outline </v-icon> {{task.patient.email}}</p>
                                </v-row>
                                <p class="mb-0" v-for="cup in task.orders" :key="cup.order.id"><v-icon > mdi-text-box-outline </v-icon> {{cup.order.cup_name}}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                <!-- </v-btn> -->
            </template>
            <template v-slot:default>
                <v-card>
                    <v-toolbar color="purple_glya text-h6" dark height="50"> Descripción de actividad</v-toolbar>
                    <v-card-text class="mt-5">                    
                        <v-row class="mb-0 info-activity" >
                            <v-icon color="green">mdi-account</v-icon>
                            <h3 class="pl-3 mt-2">{{task.patient.fullName}}</h3>
                            <v-chip class="ma-2" color="success" outlined> {{task.patient.yearsAge}} años</v-chip>
                        </v-row>
                        <v-row class="mt-0">
                            <p class="mb-0">{{task.patient.id_type}} {{task.patient.nid}} | Grupo sanguineo: {{task.patient.blood_type}} {{task.patient.rh_factor}}</p>
                            <p class="mb-0">{{task.patient.sex=='M' ? 'Masculino' : 'Femenino'}}, {{task.patient.yearsAge}} años {{task.patient.monthsAge}} meses | Fecha de nacimeinto: {{task.patient.birthdate_format}}    </p>
                        </v-row>
                        <v-card class="mt-5">
                            <v-card-title class="blue white--text pt-1 pb-1">
                                {{task.schedule_date}} - {{task.schedule_hour}}<b> |  {{task.status}}</b>
                            </v-card-title>
                            <v-card-text>
                                <p> <v-icon > mdi-home-outline </v-icon> {{task.patient.address}} {{task.patient.current_residence}} </p>
                                <v-row>
                                    <p class="pl-3 mb-0"> <v-icon > mdi-phone-outline </v-icon>  {{task.patient.phone}}</p>
                                    <p class="pl-3"> <v-icon > mdi-email-outline </v-icon> {{task.patient.email}}</p>
                                </v-row>
                                <p class="mb-0" v-for="cup in task.orders" :key="cup.order.id"><v-icon > mdi-text-box-outline </v-icon> {{cup.order.cup_name}} : {{task.medication_administrations[0].medication_name}}</p>
                            </v-card-text>
                        </v-card>



                    </v-card-text>
                    <v-card-actions class="d-flex justify-space-between">
                        <v-btn @click="dialog_order = false" class="pl-5 pr-5 white--text" rounded depressed color="red" small>Cerrar
                            <v-icon right dark> mdi-close</v-icon>
                        </v-btn>   
                        <v-btn  class="pl-5 pr-5 white--text" depressed rounded color="green" small :disabled="task.status=='Finalizada' || task.status=='Cancelada'" @click="goTask(task)"> {{task.status=="Planeado"? "Iniciar actividad":"Continuar actividad"}}
                            <v-icon right dark> mdi-arrow-right</v-icon>
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </template>
        </v-dialog>


    </div>
</template>

<script>

    export default{
        
        
        data() {
            return{
                task:this.$props.actualTask.encounter,
                dialog_order:false,
            }
        },
        props: {
            patient_id: {
                type: String,
                default: ""
            },
            createform:{
                type: Boolean,
                default:true
            },
            actualTask:{
                default:{}
            }
        },
        created() {
            this.$store.commit('SET_LAYOUT', 'MainLayout')
        },
        methods:{
            state: function(estado)
            {             
                switch (estado){
                    case 'En proceso':
                    return "yellow" ;              
                    case 'Demorada':
                    return "red" ;             
                    case 'Planeado':
                    return "blue" ;       
                    case 'Cancelada':
                    return "grey" ;   
                    case 'Finalizada':
                    return "green" ; 
                    default:
                    return "grey";                   
                }
            },
            goTask(task)
            {   let dateCheckIn=new Date();
                if(task.status !== "En proceso")
                {
                    this.$store.dispatch('getLocation').then((res)=>
                    {
                        console.log(res);
                        this.$store.state.checkInData.location={latitude:res.coords.latitude,longitude:res.coords.longitude};
                        this.$store.state.checkInData.taskID=task.id;
                        this.$store.state.checkInData.dateTimeCheckIn=dateCheckIn;
                        this.$store.state.presentTask=task;
                        if(task.status === "Planeado")
                        {
                            let indexTask=0;
                            this.$store.state.tasks['Planeado'].forEach((task_2,index)=>
                            {
                                if(task_2.encounter.id===task.id)
                                {
                                    indexTask=index;
                                }
                            })
                            this.$store.state.tasks['Planeado'].splice(indexTask,1);
                            this.$store.state.presentTask.status="En proceso";
                            this.$store.state.tasks['Activas'].push({'encounter':this.$store.state.presentTask});
                        }
                        this.$router.push('/validation');
                    }).catch((err)=>
                    {
                        console.log(err);
                        this.$store.state.checkInData.location={latitude:44,longitude:44};
                        this.$store.state.checkInData.taskID=task.id;
                        this.$store.state.checkInData.dateTimeCheckIn=dateCheckIn;
                        this.$store.state.presentTask=task;
                        if(task.status === "Planeado")
                        {
                            let indexTask=0;
                            this.$store.state.tasks['Planeado'].forEach((task_2,index)=>
                            {
                                if(task_2.encounter.id===task.id)
                                {
                                    indexTask=index;
                                }
                            })
                            this.$store.state.tasks['Planeado'].splice(indexTask,1);
                            this.$store.state.presentTask.status="En proceso";
                            this.$store.state.tasks['Activas'].push({'encounter':this.$store.state.presentTask});
                        }
                        this.$router.push('/validation');
                    });
                }else
                {
                    this.$store.state.presentTask=task;
                    this.$store.dispatch('readFromStorage','taskForm'+this.$store.state.presentTask.id).then(res=>
                    {
                        res=JSON.parse(res);
                        if(res!==null)
                        {
                            this.$store.state.taskFormTemp=res;
                        }
                        this.$router.push('/validation');
                    })
                }
            }
        },
        computed: {
            // verror: function () {
            //     return this.$store.state.form.reserror
            // }
            // time_unity: function(){
            //     if (this.order.type=="Medicamentos"){

            //     }
            // }        
        },
        components: {
            
        }
    }
</script>
<style>
  .date-activity {
  text-align: center;
  border-radius: 8px 0px 0px 8px;
  }
  .date-activity p{
    margin-bottom: 0px;
  }
  .info-activity .v-chip{
    padding: 5px 10px;
    height: 20px;
  }
  @media (max-width:600px){
    .date-activity {
  border-radius: 8px 8px 0px 0px;
  }
  }
</style>