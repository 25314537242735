<template>
<v-dialog v-model="dialog_order" max-width="100%" persistent>
  <div>
    
    <l-map
      :zoom="zoom"
      :center="center"
      style="height: 500px;"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <!-- Use default icon -->
      <l-marker :lat-lng="[47.41322, -1.219482]" />
      <!-- Use icon given in icon property -->
      <l-marker
        :lat-lng="[47.41322, -1.209482]"
        :icon="icon"
      />
      <!-- Create image icon (icon) from l-icon tag -->
      <l-marker :lat-lng="[47.41322, -1.199482]">
        <l-icon
          :icon-size="dynamicSize"
          :icon-anchor="dynamicAnchor"
          icon-url="/images/baseball-marker.png"
        />
      </l-marker>
      <!-- Create HTML icon (divIcon) by providing content inside the l-icon tag -->
      <l-marker :lat-lng="[47.41322, -1.189482]">
        <l-icon
          :icon-anchor="staticAnchor"
          class-name="someExtraClass"
        >
          <div class="headline">
            {{ customText }}
          </div>
          <img src="/images/layers.png">
        </l-icon>
      </l-marker>
    </l-map>
  
  </div>
</v-dialog>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { latLng, icon } from "leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  mounted() {
      setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 250);
},
  data() {
    return {
      zoom: 13,
      center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      icon: icon({
        iconUrl: "static/images/baseball-marker.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      staticAnchor: [16, 37],
      customText: "Foobar",
      iconSize: 64,
      dialog_order:true
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  methods: {},
  
};
</script>

<style>

</style>
