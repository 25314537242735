import Vue from 'vue'
import Vuex from 'vuex'
import { Geolocation } from '@capacitor/geolocation';
/* eslint-disable */
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    taskFormTemp:"",
    sessionLocation:
    {
      latitude:4.629786419492299,
      longitude:-74.0926176189906
    },
    dateLimit:"",
    taskOk:true,
    careGiverData:
    {
      fullName:"",
      idType:"",
      idNumber:"",
      relationship:0
    },
    checkInData:
    {
      taskID:"",
      dateTimeCheckIn:"",
      location:
      {
        latitude:0,longitude:0, 
      }
    },
    presentTask:{},
    idTypes:
    [
      {"value":"CC","key":"CEDULA DE CIUDADANIA"},
      {"value":"CE","key":"CEDULA DE EXTRANJERIA"},
      {"value":"NIP","key":"NUMERO DE IDENTIFICACION PERSONAL"},
      {"value":"NIT","key":"NUMERO DE IDENTIFICACION TRIBUTARIA"},
      {"value":"TI","key":"TARJETA DE IDENTIDAD"},
      {"value":"PAP","key":"PASAPORTE"}
    ],
    relationships:
    [
      {"value":1,"key":"ESPOSO(A)"},
      {"value":2,"key":"HIJO(A)"},
      {"value":3,"key":"HERMANO(A)"},
      {"value":4,"key":"PADRE"},
      {"value":5,"key":"OTROS"},
      {"value":6,"key":"NIETO(A)"},
      {"value":7,"key":"SOBRINO(A)"},
      {"value":8,"key":"TIO(A)"},
      {"value":9,"key":"PRIMO(A)"},
      {"value":10,"key":"SUEGRO(A)"},
      {"value":11,"key":"MADRE"},
    ],
    venousAccessTypes:
    [
      {"value":1,"key":"CENTRAL"},
      {"value":2,"key":"PERIFERICO"},
    ],
    layout: 'ClearLayout',
    pageForm: 1,
    tasks:{
      'Activas':[],
      'Finalizadas':[
        {
          "encounter":
          {
              "status":"Finalizada",
              "type":null,
              "family":null,
              "family_orders":
              [
                  {
                      "$oid":"63ffed06337953095a622de4"
                  },
                  {
                      "$oid":"63ffec17337953095a622de3"
                  }
              ],
              "order_date":null,
              "schedule_date":"2023-03-01T22:48:06.293+00:00",
              "checkin_date":null,
              "draft_date":null,
              "checkout_date":null,
              "remote":false,
              "last_encounter":false,
              "tags":[],
              "related_encounters":null,
              "id":"63ffed1c337953095a622de5",
              "responsible":
              {
                  "id":"6388ad04337953127c248ea9",
                  "nid":"123456",
                  "id_type":"CC",
                  "fullName":"SUPER ADMIN ",
                  "role":"Admin"
              },
              "orders":
              [
                  {
                      "order":
                      {
                          "status":"No gestionada",
                          "type":"Medicamentos",
                          "start_time":"2023-03-01T22:48:06.293+00:00",
                          "programmable":false,
                          "time_unit":"Días",
                          "days":1,
                          "frecuency":1,
                          "amount":1,
                          "medication_id":"000004",
                          "cup_id":"000001",
                          "cup_name":"Aplicación de medicamento",
                          "responsible":
                          {
                              "$oid":"6388ad04337953127c248ea9"
                          },
                          "description":null,
                          "triage":false,
                          "remote":false,
                          "encounter_prescription":null,
                          "only":true,
                          "domiciliary":false,
                          "news":null,
                          "recommendations":null,
                          "cup_data":null,
                          "dose":2,
                          "unity":"unidades",
                          "id":"63ffed06337953095a622de4",
                          "form":"APLICACION"
                      }
                  },
                  {
                      "order":
                      {
                          "status":"No gestionada",
                          "type":"Terapias",
                          "start_time":"2023-03-01T22:48:06.293+00:00",
                          "programmable":false,
                          "time_unit":"Días",
                          "days":1,
                          "frecuency":null,
                          "amount":1,
                          "medication_id":null,
                          "cup_id":"890110",
                          "cup_name":"Aplicación de medicamento",
                          "responsible":
                          {
                              "$oid":"6388ad04337953127c248ea9"
                          },
                          "description":null,
                          "triage":false,
                          "remote":false,
                          "encounter_prescription":null,
                          "only":true,
                          "domiciliary":false,
                          "news":null,
                          "recommendations":null,
                          "cup_data":null,
                          "dose":null,
                          "unity":null,
                          "id":"63ffec17337953095a622de3",
                          "form":null
                      }
                  }
              ],
              "patient":
              {
                  "religion":"Cristianismo",
                  "names":"MARLON danilo",
                  "surnames":"GOmez GOMEZ",
                  "fullName":"MARLON DANILO GOMEZ GOMEZ",
                  "nid":"1077033011",
                  "status":"Activo",
                  "id_type":"CC",
                  "birthdate":"1995-07-16",
                  "sex":"M",
                  "email":"marlomnp@hotmail.com",
                  "phone":"3003456789",
                  "phone_2":null,
                  "regime":"subsidiado",
                  "address":"cra 23 # 73 -09 ",
                  "blood_type":"B",
                  "rh_factor":"-",
                  "yearsAge":27,
                  "monthsAge":7,
                  "deceased":false,
                  "social_level":null,
                  "civil_status":"Casado(a)",
                  "scholarship":5,
                  "contributor":true,
                  "displaced":true,
                  "ethnic_group":7,
                  "external_suburb":null,
                  "id":"6388ad78337953127c249272",
                  "full_name":"MARLON DANILO GOMEZ GOMEZ",
                  "birthdate_format":"16 de July de 1995",
                  "caregivers":[],
                  "tags":
                  [
                      {
                          "type":null,
                          "name":"nuevaaa",
                          "model":"Patient",
                          "system":false,
                          "hidden":false,
                          "active":true,
                          "color":"red",
                          "id":"63adaa8a33795320deeb39da",
                          "client_id":"6388acf73379531260736306"
                      },
                      {
                          "type":null,
                          "name":"6664",
                          "model":"Patient",
                          "system":false,
                          "hidden":false,
                          "active":true,
                          "color":"light-green",
                          "id":"63b349dd3379530b1636b697",
                          "client_id":"6388acf73379531260736306"
                      },
                      {
                          "type":null,
                          "name":"0000",
                          "model":"Patient",
                          "system":false,
                          "hidden":false,
                          "active":true,
                          "color":"blue-grey",
                          "id":"63b7220c3379530b1636b6c3",
                          "client_id":"6388acf73379531260736306"
                      },
                      {
                          "type":null,
                          "name":"iuu",
                          "model":"Patient",
                          "system":false,
                          "hidden":false,
                          "active":true,
                          "color":"purple",
                          "id":"63c5bb3e33795347b7b2ddf0",
                          "client_id":"6388acf73379531260736306"
                      },
                      {
                          "type":null,
                          "name":"hola",
                          "model":"Patient",
                          "system":false,
                          "hidden":false,
                          "active":true,
                          "color":"blue",
                          "id":"63adab5833795320deeb39de",
                          "client_id":"6388acf73379531260736306"
                      }
                  ]
              },
              "medication_administrations":
              [
                  {
                      "medication_id":"000002",
                      "medication_name":"MEDICAMENTO 2020M-012970-R3 APRONAX ® TABLETAS 550 MG NAPROXENO"
                  }
              ]
          }
        }
      ],
      "Planeado":
      [
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-03",
      "schedule_hour":"06:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3d6",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
      "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-03",
    "schedule_hour":"18:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3d8",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-04",
            "schedule_hour":"06:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3da",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
      "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-04",
    "schedule_hour":"18:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3dc",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
    "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-05",
    "schedule_hour":"06:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3de",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
      "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-05",
    "schedule_hour":"18:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3e0",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
    "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-06",
    "schedule_hour":"06:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3e2",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
      "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-06",
    "schedule_hour":"18:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3e4",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
    "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-07",
    "schedule_hour":"06:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3e6",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
    "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-07",
    "schedule_hour":"18:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3e8",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
    "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-08",
    "schedule_hour":"06:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3ea",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
      "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-08",
    "schedule_hour":"18:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3ec",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
      "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-09",
    "schedule_hour":"06:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3ee",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
      "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        },
        {
          "encounter": {
            "status": "Planeado",
            "type": null,
            "family": null,
            "family_orders": [
              
            ],
            "order_date": null,
            "schedule_date": "2023-03-09",
    "schedule_hour":"18:00",
            "checkin_date": null,
            "draft_date": null,
            "checkout_date": null,
            "remote": false,
            "last_encounter": false,
            "tags": [
              
            ],
            "related_encounters": null,
            "id": "6401718d3379530ee361a3f0",
            "responsible": {
              "id": "6388ad04337953127c248ea9",
              "nid": "123456",
              "id_type": "CC",
              "fullName": "DIANA VILLA",
              "role": "Admin"
            },
            "orders": [{
              "order": {
                "status": "No gestionada",
                "type": "Medicamentos",
                "start_time": "2023-03-03T03:29:20.244+00:00",
                "programmable": false,
                "time_unit": "Días",
                "days": 7,
                "frecuency": null,
                "amount": 14,
                "medication_id": "002595",
                "cup_id": "000001",
      "cup_name":"aplicación de medicamento",
                "responsible": {
                  "$oid": "6388ad04337953127c248ea9"
                },
                "description": null,
                "triage": false,
                "remote": false,
                "encounter_prescription": null,
                "only": false,
                "domiciliary": false,
                "news": null,
                "recommendations": null,
                "cup_data": null,
                "dose": 2,
                "unity": "ml",
                "id": "640170be3379530ee361a3d5",
                "form": "APLICACION"
              }
            }],
            "patient": {
              "religion": null,
              "names": "JUAN FERNANDO",
              "surnames": "BARON LOPEZ",
              "fullName": "JUAN FERNANDO BARON LOPEZ",
              "nid": "79601276",
              "status": "Activo",
              "id_type": "CC",
              "birthdate": "1992-05-06",
              "sex": "M",
              "email": "juan@juan.com",
              "phone": "3102622222",
              "phone_2": null,
              "regime": "contributivo",
              "address": "Carrera 13 20-20",
              "current_residence":"Bogotá",
              "blood_type": "A",
              "rh_factor": "+",
              "yearsAge": 30,
              "monthsAge": 9,
              "deceased": false,
              "social_level": null,
              "civil_status": null,
              "scholarship": null,
              "contributor": true,
              "displaced": false,
              "ethnic_group": 7,
              "external_suburb": null,
              "id": "640169903379530ee361a3cf",
              "full_name": "JUAN FERNANDO BARON LOPEZ",
              "birthdate_format": "06 de May de 1992",
              "caregivers": [
                
              ],
              "tags": [
                {
                  "type": null,
                  "name": "Estatura:Baja",
                  "model": "Patient",
                  "system": false,
                  "hidden": false,
                  "active": true,
                  "color": "indigo",
                  "id": "64016ae63379530ee361a3d1",
                  "client_id": "6388acf73379531260736306"
                }
              ]
            },
            "medication_administrations":
            [
              {
                "concentration": "A",
                "active_principle": "VANCOMICINA CLORHIDRATO EQUIVALENTE A VANCOMICINA",
                "measurement_unit": "mg",
                "pharmaceutical_form": "POLVO ESTERIL PARA RECONSTITUIR A SOLUCION INYECTABLE",
                "vaccine_type": null,
                "presentation": "POR CADA VIAL",
                "invima_register": "2021M-0002049-R2",
                "date_register": "2002-11-13",
                "type": "Medicamento",
                "medication_id": "002595",
                "medication_name": "MEDICAMENTO 2021M-0002049-R2 VANCOCIN ® CP INYECTABLE VANCOMICINA"
              }
            ]
          }
        }
      ]
  }
  },
  getters: {
  },
  mutations: {
    SET_LAYOUT (state, newLayout) {
      state.layout = newLayout
    },
    setPage(state, newPage) {
      state.pageForm = newPage;
    }
  },
  actions: {
    doGet(context,data)
    {
      return new Promise((resolve,reject)=>
      {
        const axios = require('axios');
        axios.get(data.url,{headers:data.headers,params:data.params}).then((resp:any)=>
        {
          resolve(resp);
        }).catch((err:any)=>
        {
          reject(err);
        });
      })
      
    },
    login(context,userCredentials)
    {
      return new Promise((resolve, reject) =>
      {
        const data=
        {
          url:'https://oxicovid.glya.co/get_token.json',
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'X-Practitioner-Email': 'adminsalud@gmail.com',
            'X-Practitioner-Token': 'eUEGn5tPG7z7fJwRU9uK'
          },
          params:userCredentials
        }
        this.dispatch("doGet",data).then((resp)=>
        {
          resolve(resp);
        }).catch((err)=>
        {
          reject(err);
        })
      })
      
    },
    getLocation()
    {
      return Geolocation.getCurrentPosition();
    },
    getTasks(context,dataInterval)
    {
      return new Promise((resolve, reject) =>
      {
        const data=
        {
          url:'https://gladyapi.glya.co/encounters/get_list',
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'X-Practitioner-Email': 'adminsalud@gmail.com',
            'X-Practitioner-Token': 'eUEGn5tPG7z7fJwRU9uK'
          },
          params:dataInterval
        }
        this.dispatch("doGet",data).then((resp)=>
        {
          if(resp.data['Planeado'].length > 0)
          {
            if(!resp.data['Planeado'][0].encounter.orders.length)
            {
              console.log("Entró al segundo if")
              resp.data['Planeado'].forEach((element:any,index:any) =>
              {
                resp.data['Planeado'][index].encounter.orders=[{'order':element.encounter.orders.order}]
              });
            }
          }
          resolve(resp);
        }).catch((err)=>
        {
          reject(err);
        })
      })
    },
    saveInStorage(context,field)
    {
      localStorage.setItem(field.name, field.data);
    },
    readFromStorage(context,elementName)
    {
      return localStorage.getItem(elementName);
    },
  },
  modules: {
  }
})
