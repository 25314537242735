<template>
  <div class="contenedorComponente">
    <v-col cols="12" class="d-flex flex-row justify-space-between">
      <h1 class="mb-5">Mis Actividades</h1>
      <div >
        <MapView></MapView>
      </div>
    </v-col>
    <v-container>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <BaseDate label="Fecha de expiración" outlined=true myclass="filter" v-model="dateAlpha"></BaseDate>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <BaseDate label="Fecha de expiración" outlined=true myclass="filter"  v-model="dateOmega"></BaseDate>
        </v-col>
        <v-col>
          <v-btn cols="12" sm="4" class="white--text" elevation="0" block rounded color="green" @click="getTaskList()">Filtrar</v-btn>
        </v-col>
        
      </v-row>
      
        <div v-for="(list,value) in taskList()" :key="list.id">
          <v-col cols="12" class="title-activities mb-5 mt-5" v-if="list.length>0">
            <v-row>
              <h1 class="purple_glya_dark--text pl-2 pr-3 mb-0 white text-title">{{ value }}</h1>
            </v-row>
          </v-col>
          <div v-for="t in list" :key="t.id">
            <CardActivity :actualTask="t"></CardActivity>
          </div>
        </div>
        
    </v-container>
  </div>
</template>
<script>
  import CardActivity from '@/components/view_items/CardActivity.vue';
  import BaseDate from '@/components/forms_items/BaseDate.vue';
  import MapView from '@/components/view_items/MapView.vue';
  export default {
    
    components:{
      CardActivity,
      MapView,
      BaseDate
    },
    data() {
      return {
        dateAlpha:"",
        dateOmega:""
      }
    },
    created() {
        this.getTaskList();
        this.$store.commit('SET_LAYOUT', 'MainLayout')
    },
    
    methods:
    {
      taskList () {
        return this.$store.state.tasks
      },
      getTaskList()
      {
        let dataInterval=
        {
          date1:this.dateAlpha,
          date2:this.dateOmega,
          responsible:"640f8c7cd1d826180f586eb8"
        }
        this.$store.dispatch('getTasks',dataInterval).then((res)=>
        {
            this.$store.state.tasks=res.data;
            if(!this.$store.state.tasks["Activas"])
            {
              this.$store.state.tasks["Activas"]=[];
            }
            if(!this.$store.state.tasks["Finalizadas"])
            {
              this.$store.state.tasks["Finalizadas"]=[];
            }
        }).catch((err)=>
        {
            console.log(err);
        });
      }
    }
    };
</script>
<style>
  .title-activities{
    border-bottom: 2px dashed #3949AB;
    position: relative;
  }
  .text-title{
    position: absolute;
    bottom: -5px;
  }
</style>
