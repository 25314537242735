import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                blue: '#54AFF1',
                green: '#46AC62',
                red: '#E76C73',
                grey: '#9F9C8C',
                yellow: '#F0BD59',
                blue_glya_dark: '#273057',
                blue_glya_ligth: '#1276BC',
                purple_glya:'#5E35B1',
                purple_glya_dark:'#3949AB',
                purple_glya_ligth:'#C9C5FA'

            },
        },
    },
});
