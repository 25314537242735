<template>
    <div class="inputglya" :class="inputsize">
        <v-text-field
        :type="type"
        :label="label"
        :placeholder="place || label"
        :solo="solo"
        :outlined="outlined"
        :filled="filled"
        :value="value"
        @input="updateValue"
        required
        :error-messages="nameErrors"
        :class="myclass"
        :prefix="prefix"
    ></v-text-field>
        
    </div>
</template>

<script>
export default {

    name:"BaseInput",

    props: {
        validador:{
            default:"namesSurnames"
        }, myclass:String,filter:{},element:{}, prefix:{}, solo:{}, outlined:{default:false}, filled:{},type:{},label:{},transform:{},inputsize:{},idtype:{default:"CC"},
        place:{}, value:String
    },
    data() {
        return {
            id: Math.floor(Math.random() * 1000000),
            minl: 3,
            validations: {
                namesSurnames: {
                    minLength: 3
                }
            },        
        
        }
    },
    methods: {
        updateValue(event)
        {
            this.$emit('input', event)
        },
    },
    computed: { 
        
    },
    components: {
    },
}

</script>

<style>

</style>
